import React, { useMemo } from 'react'
import { groupBy, keys } from 'lodash'
import { round } from '../../utils/NumberUtil'
import { Card, CardContent, CardHeader, Grid2, Icon, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import { BRANCH, DISPLAY_MODE, getBranchColor, getInstanceColor, STATUS } from 'dashboard/utils/DashboardUtils'
import { Masonry } from '@mui/lab'
import { mainBlue } from 'AppTheme'
import Chip from 'components/Chip'

const InstanceCard = ({
    instance = {},
    onClick = () => { },
    displayMode,
}) => {
    const message = [instance.messageBackend, instance.messageDB, instance.messageFrontend].filter(m => !!m).join('\n\n')

    const isOK = instance.statusBackend === STATUS.OK && instance.statusDB === STATUS.OK && instance.statusFrontend === STATUS.OK

    return (
        <Tooltip title={message ? <span style={{ whiteSpace: 'pre-wrap' }}>{message}</span> : undefined}>
            <Card sx={{ backgroundColor: getInstanceColor(instance), cursor: 'pointer' }} onClick={() => onClick({ instance: instance.name })}>
                <CardContent
                    sx={{
                        padding: '5',
                        ['&:last-child']: {
                            paddingBottom: '5',
                        },
                    }}
                >
                    <Grid2 container spacing={0.5} alignItems='center'>
                        <Grid2 container size={12} alignItems='center'>
                            {instance.statusBackend !== STATUS.OK && (
                                <Grid2 size='auto'>
                                    <Icon sx={{ fontSize: '0.9rem' }}>thunderstorm</Icon>
                                </Grid2>
                            )}
                            {instance.statusDB !== STATUS.OK && (
                                <Grid2 size='auto'>
                                    <Icon sx={{ fontSize: '0.9rem' }}>storage</Icon>
                                </Grid2>
                            )}
                            {instance.statusFrontend !== STATUS.OK && (
                                <Grid2 size='auto'>
                                    <Icon sx={{ fontSize: '0.9rem' }}>desktop_access_disabled</Icon>
                                </Grid2>
                            )}
                            {isOK && (
                                <Grid2 size='auto'>
                                    <Icon sx={{ fontSize: '0.9rem' }}>wb_sunny</Icon>
                                </Grid2>
                            )}
                            <Grid2 size='grow' sx={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                                {`${instance.name} : ${instance.statusBackend !== STATUS.KO ? 'UP' : `${instance.nbServicesKo ?? 0} KO`}`}
                            </Grid2>
                        </Grid2>
                        {displayMode === DISPLAY_MODE.BACK && (
                            <>
                                <Grid2 size={6}>
                                    <div style={{ fontSize: '0.8rem' }}>
                                        {instance.nbServicesTotal ?? 0} MS
                                    </div>
                                </Grid2>
                                <Grid2 size={6}>
                                    <div style={{ fontSize: '0.8rem' }}>
                                        {round(instance.maxMemory / 1000, 1)} GO
                                    </div>
                                </Grid2>
                            </>
                        )}
                        {displayMode === DISPLAY_MODE.DB && (
                            <>
                                <Grid2 size={12}>
                                    <div style={{ fontSize: '0.8rem' }}>
                                        Play id: {instance.playEvolutionLastId}
                                    </div>
                                </Grid2>
                            </>
                        )}
                        {displayMode === DISPLAY_MODE.FRONT && (
                            <>
                                <Grid2 size={12}>
                                    <div style={{ fontSize: '0.8rem' }}>
                                        Version front-end: {instance.frontendVersions[0]?.version}
                                    </div>
                                </Grid2>
                            </>
                        )}
                    </Grid2>
                </CardContent>
            </Card>
        </Tooltip>
    )
}

InstanceCard.propTypes = {
    instance: PropTypes.shape({
        branch: PropTypes.string,
        name: PropTypes.string,
        services: PropTypes.arrayOf(PropTypes.shape({
            apiName: PropTypes.string,
            commitDate: PropTypes.string,
            deployDate: PropTypes.string,
            gitlabName: PropTypes.string,
            id: PropTypes.number,
            isDefault: PropTypes.bool,
            isRunning: PropTypes.bool,
            lastCommit: PropTypes.string,
            launchDate: PropTypes.string,
            memory: PropTypes.string,
            name: PropTypes.string,
            port: PropTypes.number,
        })),
    }),
    onClick: PropTypes.func,
    displayMode: PropTypes.string,
}

const BranchCard = ({
    branch = BRANCH.UNKNOWN,
    instances = [],
    onClick = () => { },
    displayMode,
}) => {
    return (
        <Card sx={{ backgroundColor: '#eee' }}>
            <CardHeader
                title={<Chip label={branch} color={getBranchColor(branch)} />}
                titleTypographyProps={{ variant: 'h6', align: 'center' }}
                onClick={() => onClick({ branch })}
                style={{ cursor: 'pointer' }}
            />
            <CardContent
                sx={{
                    padding: '5',
                    ['&:last-child']: {
                        paddingBottom: '5',
                    },
                }}
            >
                <Grid2 container spacing={0.5} direction='column'>
                    {instances.map(instance => (
                        <Grid2 size={12} key={instance.id}>
                            <InstanceCard
                                instance={instance}
                                onClick={onClick}
                                displayMode={displayMode}
                            />
                        </Grid2>
                    ))}
                </Grid2>
            </CardContent>
        </Card>
    )
}

BranchCard.propTypes = {
    branch: PropTypes.string,
    instances: PropTypes.arrayOf(PropTypes.shape({
        branch: PropTypes.string,
        name: PropTypes.string,
        services: PropTypes.arrayOf(PropTypes.shape({
            apiName: PropTypes.string,
            commitDate: PropTypes.string,
            deployDate: PropTypes.string,
            gitlabName: PropTypes.string,
            id: PropTypes.number,
            isDefault: PropTypes.bool,
            isRunning: PropTypes.bool,
            lastCommit: PropTypes.string,
            launchDate: PropTypes.string,
            memory: PropTypes.string,
            name: PropTypes.string,
            port: PropTypes.number,
        })),
    })),
    onClick: PropTypes.func,
    displayMode: PropTypes.string,
}

const DatacenterCard = ({
    datacenter = {},
    onClick = () => { },
    displayMode,
}) => {
    const groupByBranch = groupBy(datacenter.instances, i => i.branch ?? BRANCH.UNKNOWN)
    return (
        <Card sx={{ backgroundColor: mainBlue }}>
            <CardHeader
                title={`${datacenter.server}`}
                titleTypographyProps={{ align: 'center' }}
                onClick={() => onClick({ datacenter: datacenter.server })}
                style={{ cursor: 'pointer', color: 'white' }}
            />
            <CardContent
                sx={{
                    padding: '5',
                    ['&:last-child']: {
                        paddingBottom: '5',
                    },
                }}
            >
                <Grid2 container spacing={1}>
                    {keys(groupByBranch).map(branch => (
                        <Grid2 size={12} key={branch}>
                            <BranchCard
                                branch={branch}
                                instances={groupByBranch[branch]}
                                onClick={onClick}
                                displayMode={displayMode}
                            />
                        </Grid2>
                    ))}
                </Grid2>
            </CardContent>
        </Card>
    )
}

DatacenterCard.propTypes = {
    datacenter: PropTypes.shape({
        server: PropTypes.number,
        instances: PropTypes.arrayOf(PropTypes.shape({
            branch: PropTypes.string,
            name: PropTypes.string,
            services: PropTypes.arrayOf(PropTypes.shape({
                apiName: PropTypes.string,
                commitDate: PropTypes.string,
                deployDate: PropTypes.string,
                gitlabName: PropTypes.string,
                id: PropTypes.number,
                isDefault: PropTypes.bool,
                isRunning: PropTypes.bool,
                lastCommit: PropTypes.string,
                launchDate: PropTypes.string,
                memory: PropTypes.string,
                name: PropTypes.string,
                port: PropTypes.number,
            })),
        })),
    }),
    onClick: PropTypes.func,
    displayMode: PropTypes.string,
}

const KwaKeUpMap = ({
    instances = [],
    onClick = () => { },
    displayMode,
}) => {
    const formattedDatacenters = useMemo(() => {
        const groupByServer = groupBy(instances, 'server')
        return keys(groupByServer).map(server => {
            return {
                server: parseInt(server),
                instances: groupByServer[server],
            }
        })
    }, [instances])

    return (
        <Masonry columns={8} spacing={1}>
            {formattedDatacenters.map(datacenter => (
                <DatacenterCard
                    key={datacenter.server}
                    datacenter={datacenter}
                    onClick={onClick}
                    displayMode={displayMode}
                />
            ))}
        </Masonry>
    )
}

KwaKeUpMap.propTypes = {
    instances: PropTypes.arrayOf(PropTypes.shape({})),
    onClick: PropTypes.func,
    displayMode: PropTypes.string,
}

export default KwaKeUpMap
