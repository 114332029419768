import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import moment from 'moment'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import AuthAction from 'admin/actions/AuthAction'

const getPayload = () => {
    const token = localStorage.getItem('aquaparc-token')
    if (token) {
        return atob(token.split('.')[1])
    }
    return ''
}

const AuthenticatedComponent = ({ Component, ...props }) => {
    const dispatch = useDispatch()

    const {
        user,
    } = useSelector(store => ({
        user: store.AdminReducer.user,
    }), shallowEqual)

    const payload = getPayload()

    useEffect(() => {
        // if (window.location.href.includes('?slackRedirect=true')) {
        //     const matches = [...window.location.href.matchAll('code=([a-z0-9A-Z\\.]*)')]
        //     if (matches.length && matches[0].length && matches[0][1]) {
        //         if (window.location.href.includes('localhost:3002')) {
        //             redirectURI(`http://localhost:3002/#/slackRedirect?code=${matches[0][1]}`)
        //         } else {
        //             redirectURI(`https://aquaparc.aquasys.fr/#/slackRedirect?code=${matches[0][1]}`)
        //         }
        //     }
        // }
        if (!user && payload) {
            const login = localStorage.getItem('aquaparc-login')
            if (login) {
                dispatch(AuthAction.fetchUser(login))
            }
        }
    }, [dispatch])

    if (!payload) {
        return <Navigate to='/login' />
    }
    const exp = moment(JSON.parse(payload).exp)
    if (moment().isAfter(exp)) {
        dispatch(AuthAction.logout())
        return <Navigate to='/login' />
    }

    if (!user) {
        return null
    }

    return <Component {...props} />
}

AuthenticatedComponent.propTypes = {
    Component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
}

export default AuthenticatedComponent