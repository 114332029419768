const BRANCH = {
    MIGRATION_QUALITE: 'migration-qualite',
    QUALI_PREPROD: 'quali-preprod',
    PREPROD: 'preprod',
    PROD: 'prod',
    DEVELOP: 'develop',
    INTEGRATION: 'integration',
    UNKNOWN: 'inconnue',
}
const STATUS = {
    OK: 'OK',
    KO: 'KO',
    WARNING: 'WARNING',
    EMPTY: 'EMPTY',
    INCOMPLETE: 'INCOMPLETE',
}

const DISPLAY_MODE = {
    BACK: 'BACK',
    DB: 'DB',
    FRONT: 'FRONT',
}

const getBranchColor = (branch) => {
    switch (branch) {
        case BRANCH.PREPROD: return '#0000FF'
        case BRANCH.PROD: return '#FF0000'
        case BRANCH.MIGRATION_QUALITE: return '#f2ed57' // jaune bière
        case BRANCH.QUALI_PREPROD: return '#800080'
        case BRANCH.DEVELOP: return '#008000'
        case BRANCH.INTEGRATION: return '#FFA756'
        default: return '#808080'
    }
}

const getInstanceColor = (instance) => {
    const status = [instance.statusBackend, instance.statusDB, instance.statusFrontend]
    if (status.some(s => s === STATUS.KO)) return '#ff7f7f'
    if (status.every(s => s === STATUS.OK)) return '#90ee90'
    return '#ffcc54'
}

export {
    BRANCH,
    STATUS,
    DISPLAY_MODE,

    getBranchColor,
    getInstanceColor,
}