import { camelCase, isArray, isObject, toLower } from 'lodash'
import { hasValue } from './NumberUtil'

const searchAllCharacters = str => toLower(camelCase(str))

const filterAllCharacters = (data, headers, searchValue) => {
    const formatedSearchValue = searchAllCharacters(searchValue)
    return data.filter(obj => headers.some(key => {
        const value = (obj[key]?.sortValue ?? obj[key]?.value) || obj[key]
        return searchAllCharacters(value)?.includes(formatedSearchValue)
    }))
}

const formatSiret = (siret) => {
    if (siret && siret.length === 14) {
        return `${siret.slice(0, 3)} ${siret.slice(3, 6)} ${siret.slice(6, 9)} ${siret.slice(9, siret.length)}`
    }
    return siret
}

const formatPhone = (phone) => {
    if (phone && phone.length === 10) {
        return `${phone.slice(0, 2)} ${phone.slice(2, 4)} ${phone.slice(4, 6)} ${phone.slice(6, 8)} ${phone.slice(8, phone.length)}`
    }
    return phone
}

const formatMilliers = (nb) => {
    const string = `${hasValue(nb) ? nb : ''}`
    const x = string.split('.')
    let x1 = x[0]
    const x2 = x.length > 1 ? `.${x[1]}` : ''
    const rgx = /(\d+)(\d{3})/
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ' ' + '$2')
    }
    return x1 + x2
}

const isJson = (string) => {
    try {
        const json = JSON.parse(string)
        return isObject(json) || isArray(json)
    } catch {
        return false
    }
}

export {
    searchAllCharacters,
    filterAllCharacters,
    formatSiret,
    formatPhone,
    formatMilliers,
    isJson,
}
