import { CardTable, SORT } from 'components/datatable/Table'
import ApplicationConf from 'conf/ApplicationConf'
import InstanceAction from 'instance/actions/InstanceAction'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ServiceAction from 'service/actions/ServiceAction'
import TimeErrorFilterFields from 'timeError/components/TimeErrorFilterFields'
import { getDuration, getLevelColor } from 'timeError/constants/TimeErrorConstant'
import DtoTimeErrorDashboard from 'timeError/dto/DtoTimeErrorDashboard'
import { getFullDate, shortenHumanize } from 'utils/DateUtil'
import useFetch from 'utils/customHook/useFetch'
import useListIndexed from 'utils/customHook/useListIndexed'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'

const TABLE_HEADERS = [
    { key: 'routingKey', value: 'Routing key' },
    { key: 'url', value: 'URL' },
    { key: 'lastInstance', value: 'Instance' },
    { key: 'lastService', value: 'Service' },
    { key: 'lastRequestStart', value: 'Début de la requête' },
    { key: 'lastProcessStart', value: 'Début du traitement' },
    { key: 'lastProcessEnd', value: 'Fin du traitement' },
    { key: 'duration', value: 'Duré' },
    { key: 'lastUser', value: 'utilisateur' },
    { key: 'lastModule', value: 'module' },
]

const formatResult = list => list.map(te => new DtoTimeErrorDashboard(te))

const TimeErrorTable = () => {
    const dispatch = useDispatch()
    const [filter, setFilter] = useState({})
    const {
        data: timeErrors = [],
        reload,
    } = useFetch(ApplicationConf.timeError.dashboard(), { method: 'POST', body: filter }, { formatResult })

    useEffect(() => {
        dispatch(ServiceAction.fetchServices())
        dispatch(InstanceAction.fetchInstances())
    }, [dispatch])

    const {
        services,
        instances,
    } = useSelector(store => ({
        services: store.ServiceReducer.services,
        instances: store.InstanceReducer.instances,
    }), shallowEqual)

    const indexedInstances = useListIndexed(instances)
    const indexedServices = useListIndexed(services)

    useUpdateEffect(() => {
        reload()
    }, [filter])

    const formattedTimeErrors = useMemo(() => {
        return timeErrors.map(te => {
            const duration = getDuration({ level: te.level, requestStart: te.lastRequestStart, processStart: te.lastProcessStart, processEnd: te.lastProcessEnd })
            return {
                routingKey: { value: te.routingKey, color: getLevelColor(te.level) },
                url: { value: te.url },
                lastInstance: { value: indexedInstances[te.lastInstance]?.name },
                lastService: { value: indexedServices[te.lastService]?.name },
                lastRequestStart: { value: getFullDate(te.lastRequestStart), sortValue: te.lastRequestStart },
                lastProcessStart: { value: getFullDate(te.lastProcessStart), sortValue: te.lastProcessStart },
                lastProcessEnd: { value: getFullDate(te.lastProcessEnd), sortValue: te.lastProcessEnd },
                duration: { value: shortenHumanize(duration), sortValue: duration },
                lastUser: { value: te.lastUser },
                lastModule: { value: te.lastModule },
            }
        })
    }, [indexedInstances, indexedServices, timeErrors])

    return (
        <>
            <TimeErrorFilterFields
                defaultFilter={filter}
                onValidate={setFilter}
            />
            <CardTable
                title={'Time errors'}
                color='#161832'

                rows={formattedTimeErrors}
                headers={TABLE_HEADERS}

                actions={[{
                    icon: 'replay',
                    onClick: reload,
                    tooltip: 'Recharger',
                }]}

                defaultSort={{ column: 'lastRequestStart', direction: SORT.DESC }}
            />
        </>
    )
}

export default TimeErrorTable