import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'

const ConfirmModal = ({
    isOpen = false,
    title = '',
    onValidate = () => {},
    onReject = () => {},
    onClose = () => { },
}) => {
    return (
        <Dialog
            fullWidth
            maxWidth='sm'
            open={isOpen}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minHeight: 'unset',
                    maxHeight: 'unset',
                },
            }}
        >
            <DialogTitle sx={{ borderBottom: 'none', backgroundColor: 'white', color: 'black' }}>{title}</DialogTitle>
            <DialogActions sx={{ borderTop: 'none' }}>
                <Button
                    onClick={() => {
                        onReject()
                        onClose()
                    }}
                    variant='outlined'
                >
                    Non
                </Button>
                <Button onClick={onValidate} variant='contained' color='primary'>
                    Oui
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ConfirmModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onReject: PropTypes.func,
}

export default ConfirmModal