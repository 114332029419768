import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import AuthAction from '../../admin/actions/AuthAction'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastActionConstant } from '../../toastr/reducers/ToastrReducer'
import { Grid2 } from '@mui/material'
import psyduck from 'assets/pictures/psyduck-pokemon.gif'

const DiscordRedirectApp = ({

}) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        const code = new URLSearchParams(location.search).get('code')
        if (!code) {
            dispatch(ToastActionConstant.error('Pas de code dans l\'url retournée par discord'))
        } else {
            dispatch(AuthAction.loginWithDiscord(code, ({ login, token }) => {
                window.localStorage.setItem('aquaparc-login', login)
                window.localStorage.setItem('aquaparc-token', token)
                navigate('/')
            }))
        }
    }, [])

    return (
        <Grid2 container alignItems='center' justifyContent='center' style={{ minHeight: '100vh' }}>
            <Grid2 size='auto'>
                <div>
                    <h2>En attente de vérification de l'authentification discord...</h2>
                    <img src={psyduck} />
                </div>
            </Grid2>
        </Grid2>
    )
}

export default DiscordRedirectApp