import { push } from '@lagunovsky/redux-react-router'
import { Card, CardContent, Dialog, DialogContent, Grid2 } from '@mui/material'
import { DELETE, SAVE } from 'components/action/ActionConstant'
import { CardTable } from 'components/datatable/Table'
import Input from 'components/form/Input'
import RadioButtons from 'components/form/RadioButtons'
import Select from 'components/form/Select'
import ConfirmModal from 'components/modal/ConfirmModal'
import { BasicDialogAction, BasicDialogTitle } from 'components/modal/DialogComponents'
import InstanceAction from 'instance/actions/InstanceAction'
import { INSTANCE_TYPE } from 'instance/constants/InstanceConstants'
import { JsonEditor } from 'json-edit-react'
import { isNil } from 'lodash'
import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import useActions from 'utils/customHook/useActions'
import useBoolean from 'utils/customHook/useBoolean'
import useParseJson from 'utils/customHook/useParseJson'
import useTitles from 'utils/customHook/useTitles'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import { isJson } from 'utils/StringUtil'
import PropTypes from 'prop-types'

const DISPLAY_MODE = {
    TEXT: 'TEXT',
    JSON: 'JSON',
}

const InstanceParameterDialog = ({
    isOpen = false,
    onClose = () => {},
    onValidate = () => {},
    parameter,
}) => {
    const [displayMode, setDisplayMode] = useState(DISPLAY_MODE.TEXT)

    const [key, setKey] = useState('')
    const [value, setValue] = useState('')

    const {
        json: jsonData,
        error,
    } = useParseJson(value ?? '{}')

    useUpdateEffect(() => {
        if (!isOpen) return

        setKey(parameter?.parameter ?? '')
        const paramValue = parameter?.value ?? ''
        setValue(paramValue)
        setDisplayMode(isJson(paramValue) ? DISPLAY_MODE.JSON : DISPLAY_MODE.TEXT)
    }, [isOpen])

    const updateJsonObj = (newJson) => {
        setValue(JSON.stringify(newJson))
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth={'lg'}
        >
            <BasicDialogTitle onClose={onClose}>
                Paramètre
            </BasicDialogTitle>
            <DialogContent style={{ overflowX: 'hidden' }}>
                <Card>
                    <CardContent>
                        <Grid2 container columnSpacing={2} rowSpacing={1} alignItems='center' style={{ paddingTop: '5px' }}>
                            <Grid2 size={6}>
                                <Input
                                    label='Paramètre'
                                    value={key}
                                    onChange={setKey}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <RadioButtons
                                    label={'Mode d\'affichage'}
                                    items={[{ value: DISPLAY_MODE.TEXT, label: 'Text' }, { value: DISPLAY_MODE.JSON, label: 'Json' }]}
                                    value={displayMode}
                                    onChange={(newMode) => {
                                        if (newMode === DISPLAY_MODE.JSON && !value) {
                                            setValue('{}')
                                        }
                                        setDisplayMode(newMode)
                                    }}
                                />
                            </Grid2>
                            <Grid2 size={12}>
                                {displayMode === DISPLAY_MODE.TEXT && (
                                    <Input
                                        label='Valeur'
                                        value={value}
                                        onChange={setValue}
                                        multiline
                                    />
                                )}
                                {displayMode === DISPLAY_MODE.JSON && (
                                    <>
                                        {!!error && (
                                            <h6 style={{ padding: '0 5 10 5', fontSize: '16px', margin: 0 }}>
                                                Erreur: {error}
                                            </h6>
                                        )}
                                        <JsonEditor
                                            data={jsonData}
                                            setData={updateJsonObj}
                                            maxWidth='100%'
                                            rootFontSize='12px'
                                            rootName='Valeur'
                                            enableClipboard={false}
                                        />
                                    </>
                                )}
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>
            </DialogContent>
            <BasicDialogAction
                buttonLabel={isNil(parameter) ? 'Créer' : 'Modifier'}
                onValidate={() => onValidate({ parameter: key, value })}
                isDisable={!key || !value}
            />
        </Dialog>
    )
}

InstanceParameterDialog.propTypes = {
    isOpen: PropTypes.bool,
    parameter: PropTypes.shape({
        parameter: PropTypes.string,
        value: PropTypes.string,
    }),
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
}

const InstanceDescriptionApp = ({

}) => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const { instance } = useSelector(store => ({ instance: store.InstanceReducer.instance }), shallowEqual)

    useTitles(() => [{
        label: 'Instance',
        href: '/instance',
    }, {
        label: isNil(instance) ? 'Chargement...' : (instance.name ?? 'Inconnue'),
        href: `/instance/${id}`,
    }, {
        label: 'Description',
        href: `/instance/${id}/description`,
    }], [instance, id])

    const {
        value: isPopinOpen,
        setTrue: onOpenPopin,
        setFalse: onClosePopin,
    } = useBoolean(false)
    const {
        value: isConfirmOpen,
        setTrue: onOpenConfirm,
        setFalse: onCloseConfirm,
    } = useBoolean(false)
    const [selectedParameter, setSelectedParameter] = useState()

    const [name, setName] = useState(instance?.name)
    const [server, setServer] = useState(instance?.server)
    const [instanceType, setInstanceType] = useState(instance?.instanceType ?? undefined)
    const [url, setUrl] = useState(instance?.url)
    const [parameters, setParameters] = useState(instance?.parameters ?? [])

    useActions(() => {
        if (isNil(instance)) return []
        const shouldPulse = name !== instance.name
                         || server !== instance.server
                         || instanceType !== instance.instanceType
                         || url !== instance.url
                         || parameters.length !== instance.parameters.length
                         || parameters.some(param => !instance.parameters.some(p => p.parameter === param.parameter && p.value === param.value))
        return [
            {
                type: SAVE,
                onClick: () => dispatch(InstanceAction.updateInstance({ id: instance.id, name, server, instanceType, url, parameters })).then(res => {
                    if (res.update > 0) {
                        dispatch(InstanceAction.fetchInstance(instance.id))
                    }
                }),
                pulse: shouldPulse,
            },
            {
                type: DELETE,
                onClick: () => dispatch(InstanceAction.deleteInstance(instance.id)).then(res => {
                    if (res.delete > 0) {
                        dispatch(push('/instance'))
                    }
                }),
            },
        ]
    }, [instance, name, server, instanceType, url, parameters ])

    useUpdateEffect(() => {
        setName(instance?.name)
        setServer(instance?.server)
        setInstanceType(instance?.instanceType ?? undefined)
        setUrl(instance?.url)
        setParameters(instance?.parameters ?? [])
    }, [instance])

    const onDeleteParameter = row => {
        setSelectedParameter(row.parameter)
        onOpenConfirm()
    }

    const onEditParameter = row => {
        setSelectedParameter(row.parameter)
        onOpenPopin()
    }

    const parameter = parameters.find(p => p.parameter === selectedParameter)

    return (
        <Grid2 container spacing={1}>
            <Grid2 size={12}>
                <Card>
                    <CardContent>
                        <Grid2 container columnSpacing={2} rowSpacing={1} alignItems='center' style={{ paddingTop: '5px' }}>
                            <Grid2 size={6}>
                                <Input
                                    label={'Nom'}
                                    value={name}
                                    onChange={setName}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Input
                                    label={'Serveur'}
                                    value={server}
                                    onChange={setServer}
                                    type='float'
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Select
                                    label={'Type d\'instance'}
                                    items={INSTANCE_TYPE}
                                    value={instanceType}
                                    onChange={setInstanceType}
                                    undefinedValue
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Input
                                    label={'Url'}
                                    value={url}
                                    onChange={setUrl}
                                />
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>
            </Grid2>
            <Grid2 size={12}>
                <CardTable
                    title='Paramètrage'

                    rows={parameters}
                    headers={[{ key: 'parameter', value: 'Paramètre' }, { key: 'value', value: 'Valeur' }]}

                    actions={[{
                        icon: 'add',
                        onClick: onOpenPopin,
                        tooltip: 'Nouveau paramètre',
                    }]}
                    lineActions={[
                        { icon: 'delete', onClick: onDeleteParameter },
                        { icon: 'edit', onClick: onEditParameter },
                    ]}
                />
                <InstanceParameterDialog
                    isOpen={isPopinOpen}
                    parameter={parameter}
                    onClose={() => {
                        setSelectedParameter()
                        onClosePopin()
                    }}
                    onValidate={newParam => {
                        setParameters(params => {
                            const param = { ...newParam, instanceId: instance?.id }
                            if (isNil(selectedParameter)) return [...params, param]
                            return params.map(p => p.parameter === selectedParameter ? param : p)
                        })
                        setSelectedParameter()
                        onClosePopin()
                    }}
                />
                <ConfirmModal
                    isOpen={isConfirmOpen}
                    title='Êtes-vous sûr de vouloir supprimer ce paramètrage ?'
                    onValidate={() => {
                        setParameters(params => params.filter(p => p.parameter !== selectedParameter))
                        onCloseConfirm()
                    }}
                    onClose={onCloseConfirm}
                />
            </Grid2>
        </Grid2>
    )
}

export default InstanceDescriptionApp
export {
    InstanceParameterDialog,
}