import { createTheme } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
// import '@mui/x-date-pickers/themeAugmentation' // bug

const mainBlue = '#35609F'
const secondaryBlue = '#53A1FF'
const darkBlue = '#161832'
const notSoDarkBlue = '#292c4e'
const greyBlue = '#4f88b5'
const lightBlue = '#9BC2E6'
const lightGrey = 'rgb(208, 208, 208)'
const mediumGrey = '#75838F'
const disabledColor = '#949494'
const selectedColor = '#e0e0e0'
const mainWhite = '#fafafa'

const AppTheme = createTheme({
    palette: {
        primary: {
            main: mainBlue,
            grey: greyBlue,
            light: lightBlue,
        },
        secondary: {
            main: secondaryBlue,
        },
        third: {
            main: darkBlue,
        },
        action: {
            disabled: '#9e9e9e',
        },
    },
    typography: {
        fontSize: 12,
    },
    checkbox: {},
    svgIcon: {},
    components: {
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '5 10',
                    margin: '0',
                    ['&:last-child']: {
                        paddingBottom: '5',
                    },
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    padding: '5 10',
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                fullWidth: true,
                InputLabelProps: {
                    shrink: false,
                },
                color: 'secondary',
            },
            styleOverrides: {
                root: ({ ownerState }) => ({
                    // height: `${(ownerState.multiline ? ownerState.rows * 20 + 6 : 26) + (ownerState.label ? 19 : 0)}px`,
                    // paddingBottom: ownerState.multiline && !ownerState.rows ? '19px' : undefined,
                    paddingBottom: ownerState.label ? '19px' : undefined,


                    '.MuiFormLabel-root': {
                        fontSize: '0.9rem',
                    },

                    '.MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #666',
                    },

                    '.MuiInputBase-root': {
                        height: !ownerState.multiline ? '26px' : undefined,
                        top: ownerState.label ? '16px' : undefined,
                        backgroundColor: 'white',
                        padding: '4px 5px',
                        lineHeight: '20px',
                        fontSize: '0.9rem',
                    },
                    '.MuiInputBase-input': {
                        height: '26px',
                    },


                    '.MuiSelect-select': {
                        paddingTop: 0,
                        paddingBottom: 0,
                        height: undefined,
                        paddingLeft: '5px',
                        lineHeight: '26px',
                        fontSize: '0.9rem',
                    },
                    '.MuiSelect-nativeInput': {
                        margin: 0,
                        padding: 0,
                        height: '26px',
                    },

                    '& .MuiInputBase-input.MuiOutlinedInput-input': {
                        border: 'none',
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: '5px',
                        marginBottom: 0,
                    },

                    '& .MuiInputLabel-outlined': {
                        top: '-19px',
                        color: '#161832',
                        fontWeight: 'bold',
                        left: '2px',
                    },
                }),
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    minHeight: '1rem',
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    '&:focus, &.Mui-focusVisible': { backgroundColor: 'transparent' },
                    padding: '2px',

                    variants: [
                        {
                            props: { size: 'custom' },
                            style: ({ ownerState }) => ({
                                '&:hover, &:focus, &.Mui-focusVisible': { backgroundColor: ownerState.sx?.backgroundColor ?? 'transparent' },
                            }),
                        },
                        {
                            props: { size: 'actionTable' },
                            style: {
                                padding: '1px',
                                fontSize: '18px',
                            },
                        },
                        {
                            props: { size: 'actionInputEnd' },
                            style: {
                                padding: '0 3px',
                                borderRadius: 0,
                                borderLeft: 'solid 1px rgba(0, 0, 0, 0.35)',
                                fontSize: '1.35rem',
                            },
                        },
                    ],
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: '2px 0',
                    maxHeight: '250px',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    height: '25px',
                },
            },
        },
        MuiDialog: {
            defaultProps: {
                fullWidth: true,
                scroll: 'paper',
            },
            styleOverrides: {
                paper: {
                    overflowY: 'hidden',
                    borderRadius: '5px',
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    backgroundColor: greyBlue,
                    color: 'white',
                    borderBottom: 'solid 1px grey',
                    fontSize: '20px',
                    lineHeight: '20px',
                    '&+.MuiDialogContent-root': {
                        paddingTop: '0.5rem !important',
                    },
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    backgroundColor: '#f0f0f0',
                    padding: '0.5rem',
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    borderTop: 'solid 1px grey',
                    padding: '8px 18px',
                },
            },
        },
        MuiAccordion: {
            defaultProps: {
                disableGutters: true,
                TransitionProps: { unmountOnExit: true, timeout: 250 },
            },
            styleOverrides: {
                root: {
                    borderRadius: '5px',
                    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 2px 10px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                },
            },
        },
        MuiAccordionSummary: {
            defaultProps: {
                expandIcon: (
                    // eslint-disable-next-line react/react-in-jsx-scope
                    <ExpandMoreIcon sx={{ fontSize: '30px', color: 'white', borderRadius: '50%', border: 'solid white 2px' }}/>
                ),
            },
            styleOverrides: {
                root: ({ ownerState }) => ({
                    color: 'white',
                    background: mainBlue,
                    borderBottom: 'solid 1px grey',
                    minHeight: 40,
                    height: 40,
                    fontSize: '15px',
                    fontWeight: 'bold',
                    borderRadius: ownerState.expanded ? '5px 5px 0 0' : '5px',
                }),
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    borderRadius: '0 0 5px 5px',
                },
            },
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true,
            },
            styleOverrides: {
                tooltip: {
                    maxWidth: 'fit-content',
                    fontSize: '0.9rem',
                    backgroundColor: notSoDarkBlue,
                },
                arrow: {
                    color: notSoDarkBlue,
                },
            },
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    overflowX: 'visible',
                    overflowY: 'hidden',
                    boxShadow: 'none',
                    padding: '0',
                },
            },
        },
        MuiTable: {
            defaultProps: {
                size: 'small',
                'aria-label': 'table',
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    height: '23px',
                    '&:hover': {
                        '& td': {
                            backgroundColor: '#f5f5f5',
                        },
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    variants: [
                        {
                            props: { variant: 'headerIcon' },
                            style: {
                                borderColor: 'black',
                                padding: '0',
                                width: '23px',
                                minWidth: '23px',
                                backgroundColor: '#FFF',
                                position: 'sticky',
                                zIndex: 3,
                                top: 0,
                            },
                        },
                        {
                            props: { variant: 'bodyIcon' },
                            style: {
                                padding: '1',
                                width: '21px',
                                minWidth: '21px',
                                backgroundColor: '#FFF',
                                position: 'sticky',
                            },
                        },
                    ],
                },
                head: {
                    borderColor: 'black',
                    padding: '0',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    textWrap: 'nowrap',
                },
                body: {
                    fontSize: '13px',
                    fontWeight: 'normal',
                    padding: '1 4',
                    textWrap: 'nowrap',

                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                paddingNone: {
                    padding: '1 0',
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    padding: '0 20',
                    ['&:last-child']: {
                        padding: '0 20',
                    },
                },
                selectLabel: {
                    fontSize: '1rem',
                },
                select: {
                    fontSize: '1rem',
                    minHeight: '1rem',
                },
                selectIcon: {
                    fontSize: '1.5rem',
                },
                displayedRows: {
                    fontSize: '1rem',
                },
                actions: {
                    '& button': {
                        '&:focus': {
                            backgroundColor: 'white',
                        },
                    },
                    '& svg': {
                        fontSize: '2rem',
                    },
                },
            },
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    '&:hover': { color: 'rgba(0, 0, 0, 0.87)' },
                    '&:focus': { color: 'rgba(0, 0, 0, 0.87)' },
                    width: '100%',
                    padding: '6px 22px 6px 4px',
                },
                icon: {
                    margin: '0',
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    padding: '3 5 3 3',
                },
            },
        },
    },
})

export default AppTheme
export {
    notSoDarkBlue,
    mainBlue,
    secondaryBlue,
    darkBlue,
    greyBlue,
    lightBlue,
    lightGrey,
    mediumGrey,
    disabledColor,
    selectedColor,
    mainWhite,
}