import ApplicationConf from 'conf/ApplicationConf'
import { InstanceActionConstant } from 'instance/reducers/InstanceReducer'
import { catchFunctionBuilder, genericPromise2, thenFunctionCreateBuilder, thenFunctionDeleteBuilder, thenFunctionUpdateBuilder } from 'utils/ActionUtils'

const InstanceAction = {
    fetchInstances: () => dispatch => genericPromise2(ApplicationConf.instance.all())
        .then((list = []) => dispatch(InstanceActionConstant.instances(list)))
        .catch(catchFunctionBuilder('Erreur lors de la récupération des instances', dispatch)),

    fetchInstance: id => dispatch => genericPromise2(ApplicationConf.instance.single(id))
        .then(res => dispatch(InstanceActionConstant.instance(res)))
        .catch(catchFunctionBuilder('Erreur lors de la récupération d\'une instance', dispatch)),
    createInstance: instance => dispatch => genericPromise2(ApplicationConf.instance.all(), { body: instance, method: 'POST' })
        .then(thenFunctionCreateBuilder('Instance créée avec succès', dispatch))
        .catch(catchFunctionBuilder('Erreur lors de la création d\'une instance', dispatch)),
    updateInstance: instance => dispatch => genericPromise2(ApplicationConf.instance.all(), { body: instance, method: 'PUT' })
        .then(thenFunctionUpdateBuilder('Instance modifiée avec succès', dispatch))
        .catch(catchFunctionBuilder('Erreur lors de la modification d\'une instance', dispatch)),
    deleteInstance: id => dispatch => genericPromise2(ApplicationConf.instance.single(id), { method: 'DELETE' })
        .then(thenFunctionDeleteBuilder('Instance supprimée avec succès', dispatch))
        .catch(catchFunctionBuilder('Erreur lors de la suppression d\'une instance', dispatch)),
}

export default InstanceAction