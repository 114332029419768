import React from 'react'
import { Grid2 } from '@mui/material'
import aquaparkPng from 'assets/pictures/aquapark.png'
import discord from 'assets/pictures/discord.png'

const LoginApp = () => {
    // const redirectURL = (window.location.href.includes('localhost') ? 'https%3A%2F%2Flocalhost%3A3002%3FslackRedirect%3Dtrue' : 'https%3A%2F%2Faquaparc.aquasys.fr%3FslackRedirect%3Dtrue')
    // .replaceAll('/', '%2F').replaceAll(':', '%3A').replaceAll('?', '%3F').replaceAll('=', '%3D')
    const redirectDiscord = window.location.href.includes('localhost') ? 'https://discord.com/oauth2/authorize?client_id=1278714443995414601&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3002&response_type=token&scope=email+identify' : 'https://discord.com/oauth2/authorize?client_id=1278714443995414601&response_type=code&redirect_uri=https%3A%2F%2Faquaparc.aquasys.fr&response_type=token&scope=email+identify'
    return (
        <Grid2 container alignItems='center' justifyContent='center' style={{ minHeight: '100vh', backgroundImage: `url(${aquaparkPng})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '100vh' }}>
            <Grid2 size='auto'>
                <div>
                    <a
                        href={redirectDiscord}
                        style={{
                            alignItems: 'center',
                            color: '#fff',
                            backgroundColor: '#1e1f22',
                            border: 0,
                            borderRadius: 4,
                            display: 'inline-flex',
                            fontFamily: 'Lato, sans-serif',
                            fontSize: 18,
                            fontWeight: 600,
                            height: 56,
                            justifyContent: 'center',
                            textDecoration: 'none',
                            width: 296,
                        }}
                    >
                        <img src={discord} style={{ height: 40, width: 40, marginRight: 12 }}/>
                        Sign in with Discord
                    </a>
                </div>
            </Grid2>
        </Grid2>
    )
}

export default LoginApp