import { Grid2 } from '@mui/material'
import { CardTable, SORT } from 'components/datatable/Table'
import { BRANCH } from 'dashboard/utils/DashboardUtils'
import { groupBy, isNil, keys, max, uniqBy } from 'lodash'
import moment from 'moment'
import React from 'react'
import PropTypes from 'prop-types'
import DtoKwaKeUpResult from 'dashboard/dto/DtoKwaKeUpResult'

const TABLE_HEADERS = [
    { key: 'instance', value: 'Instance' },
    { key: 'service', value: 'Service' },
    { key: 'server', value: 'Serveur' },
    { key: 'number', value: 'Numéro de version' },
    { key: 'lastNumber', value: 'Dernière version' },
    { key: 'commit', value: 'Commit' },
    { key: 'deploy', value: 'Deploy' },
    { key: 'launch', value: 'Launch' },
]

const BranchTable = ({
    branch = BRANCH.UNKNOWN,
    services = [],
}) => {
    const groupByService = groupBy(services, 'service.id')
    const formattedServices = keys(groupByService).flatMap(key => {
        const list = groupByService[key]
        const allVersion = uniqBy(list, 'number').map(s => s.number)
        const lastVersion = max(allVersion, v => moment(v, 'YYYY.MMDD.hhmm').valueOf)
        return list.map(l => ({ ...l, lastVersion }))
    })
    const filteredServices = formattedServices.filter(s => s.number !== s.lastVersion)
    const tableData = filteredServices.map(r => {
        return {
            instance: {
                value: r.instance.name,
                color: r.isRunning ? '#85ffab' : '#ff8e8e',
            },
            service: {
                value: r.service.name,
                color: r.isRunning ? '#85ffab' : '#ff8e8e',
            },
            server: {
                value: r.instance.server ? `dt${r.instance.server}` : undefined,
            },
            number: {
                value: r.number,
            },
            lastNumber: {
                value: r.lastVersion,
            },
            commit: {
                value: `${r.commitDate || ''} ${r.lastCommit ? `[${r.lastCommit.substring(0, 10)}]` : '[???]'}`,
                rightIcon: {
                    icon: r.lastCommit ? 'send' : undefined,
                    color: '#35609f',
                },
                onClick: r.lastCommit ? () => window.open(`https://gitlab2.aquasys.fr/sieau/${r.service.gitlabName}/-/commit/${r.lastCommit}`, '_blank') : undefined,
                color: !r.lastCommit || !r.commitDate ? '#fd7d26' : undefined,
            },
            deploy: { value: r.deployDate, color: !r.deployDate ? '#fd7d26' : undefined },
            launch: { value: r.launchDate, color: !r.launchDate ? '#fd7d26' : undefined },
            isProblem: !r.isRunning,
            isIncomplete: [r.lastCommit, r.commitDate, r.branch, r.deployDate, r.launchDate, r.memory].some(e => isNil(e)),
        }
    })

    return !!tableData.length && (
        <CardTable
            title={branch}
            color='#161832'

            rows={tableData}
            headers={TABLE_HEADERS}

            defaultSort={{ column: 'service', direction: SORT.ASC }}
        />
    )
}

BranchTable.propTypes = {
    branch: PropTypes.string,
    services: PropTypes.arrayOf(PropTypes.instanceOf(DtoKwaKeUpResult)),
}

const UnupdatedTable = ({
    results = [],
}) => {
    const groupByBranch = groupBy(results, r => r.branch ?? BRANCH.UNKNOWN)
    const orderedBranch = [
        BRANCH.PREPROD,
        BRANCH.DEVELOP,
        BRANCH.QUALI_PREPROD,
        BRANCH.MIGRATION_QUALITE,
    ]
    return (
        <Grid2 container spacing={1}>
            {orderedBranch.map(branch => (
                <Grid2 size={12} key={branch}>
                    <BranchTable
                        branch={branch}
                        services={groupByBranch[branch]}
                    />
                </Grid2>
            ))}
        </Grid2>
    )
}

UnupdatedTable.propTypes = {
    results: PropTypes.arrayOf(PropTypes.instanceOf(DtoKwaKeUpResult)),
}

export default UnupdatedTable