import { Icon, IconButton, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import EditIcon from '@mui/icons-material/Edit'
import UndoIcon from '@mui/icons-material/Undo'
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/Save'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import ClearIcon from '@mui/icons-material/Clear'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import ConfirmModal from 'components/modal/ConfirmModal'
import useBoolean from 'utils/customHook/useBoolean'
import useTimeout from 'utils/customHook/useTimeout'
import moment from 'moment'
import { HomeActionConstant } from 'home/reducers/HomeReducer'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { isNil } from 'lodash'
import { pulseGrow } from 'utils/AnimationsUtils'

const Action = ({
    icon,
    onClick,
    tooltip,
    color = 'inherit',
    'data-cy': dataCy,
}) => {
    const button = (
        <IconButton
            sx={{ fontSize: '32px', color }}
            onClick={onClick}
            data-cy={dataCy}
        >
            <Icon fontSize='inherit'>{icon}</Icon>
        </IconButton>
    )
    if (isNil(tooltip)) return button
    return (
        <Tooltip title={tooltip}>
            {button}
        </Tooltip>
    )
}

Action.propTypes = {
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    tooltip: PropTypes.string,
    color: PropTypes.string,
    'data-cy': PropTypes.string,
}

const Other = ({
    icon = '',
    tooltip = '',
    onClick = () => { },
}) => {
    return (
        <Tooltip title={tooltip}>
            <Icon sx={{ color: 'white', fontSize: '38px', cursor: 'pointer' }} onClick={onClick}>{icon}</Icon>
        </Tooltip>
    )
}

Other.propTypes = {
    icon: PropTypes.string,
    tooltip: PropTypes.string,
    onClick: PropTypes.func,
}

const New = ({
    onClick = () => {},
}) => {
    return (
        <Tooltip title='Nouveau'>
            <NoteAddIcon sx={{ color: 'white', fontSize: '38px', cursor: 'pointer' }} onClick={onClick}/>
        </Tooltip>
    )
}

New.propTypes = {
    onClick: PropTypes.func,
}

const Edit = ({
    onClick = () => { },
}) => {
    return (
        <Tooltip title='Editer'>
            <EditIcon sx={{ color: 'white', fontSize: '38px', cursor: 'pointer' }} onClick={onClick}/>
        </Tooltip>
    )
}

Edit.propTypes = {
    onClick: PropTypes.func,
}

const Cancel = ({
    onClick = () => { },
}) => {
    return (
        <Tooltip title='Annuler'>
            <UndoIcon sx={{ color: 'white', fontSize: '38px', cursor: 'pointer' }} onClick={onClick}/>
        </Tooltip>
    )
}

Cancel.propTypes = {
    onClick: PropTypes.func,
}

const Delete = ({
    onClick = () => { },
}) => {
    const {
        value: isOpen,
        setTrue: open,
        setFalse: close,
    } = useBoolean(false)

    return (
        <>
            <Tooltip title='Supprimer'>
                <DeleteIcon sx={{ color: 'white', fontSize: '38px', cursor: 'pointer' }} onClick={open} />
            </Tooltip>
            <ConfirmModal
                isOpen={isOpen}
                title={'Êtes-vous sûr de vouloir supprimer cet élément ?'}
                onValidate={() => {
                    onClick()
                    close()
                }}
                onClose={close}
            />
        </>
    )
}

Delete.propTypes = {
    onClick: PropTypes.func,
}

const Save = ({
    onClick = () => { },
    pulse = false,
}) => {
    return (
        <Tooltip title='Sauvegarder'>
            <IconButton
                sx={{ fontSize: '38px', color: '#FFF', padding: '8px', animation: pulse ? `${pulseGrow} 1s ease infinite` : undefined }}
                onClick={onClick}
            >
                <SaveIcon fontSize='inherit'/>
            </IconButton>
        </Tooltip>
    )
}

Save.propTypes = {
    onClick: PropTypes.func,
    pulse: PropTypes.bool,
}

const Clock = () => {
    const [time, setTime] = useState([])
    const { reset } = useTimeout(() => {
        setTime(moment().format('DD/MM/YYYY HH:mm:ss'))
    }, 1000)

    useEffect(() => {
        reset()
    }, [reset, time])

    return (
        <Tooltip title={time}>
            <AccessTimeIcon sx={{ color: 'white', fontSize: '38px', cursor: 'pointer' }} />
        </Tooltip>
    )
}

const Clear = ({
    onClick = () => { },
}) => {
    return (
        <Tooltip title='Supprimer'>
            <ClearIcon sx={{ color: 'white', fontSize: '38px', cursor: 'pointer' }} onClick={onClick} />
        </Tooltip>
    )
}

Clear.propTypes = {
    onClick: PropTypes.func,
}

const Upload = ({
    onClick = () => { },
}) => {
    return (
        <Tooltip title='Upload'>
            <FileUploadIcon sx={{ color: 'white', fontSize: '38px', cursor: 'pointer' }} onClick={onClick} />
        </Tooltip>
    )
}

Upload.propTypes = {
    onClick: PropTypes.func,
}

const Download = ({
    onClick = () => { },
}) => {
    return (
        <Tooltip title='Download'>
            <FileDownloadIcon sx={{ color: 'white', fontSize: '38px', cursor: 'pointer' }} onClick={onClick} />
        </Tooltip>
    )
}

Download.propTypes = {
    onClick: PropTypes.func,
}

const Sound = () => {
    const dispatch = useDispatch()
    const {
        isMute,
    } = useSelector(store => ({
        isMute: store.HomeReducer.isMute,
    }), shallowEqual)

    return (
        <Tooltip title='Couper le son'>
            <Icon sx={{ color: 'white', fontSize: '38px', cursor: 'pointer' }} onClick={() => dispatch(HomeActionConstant.toggleMute())}>
                {isMute ? 'volume_off' : 'volume_up'}
            </Icon>
        </Tooltip>
    )
}

export {
    Action,
    Other,
    New,
    Edit,
    Cancel,
    Delete,
    Save,
    Clock,
    Clear,
    Upload,
    Download,
    Sound,
}