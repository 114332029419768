import { Card, CardContent, Dialog, DialogContent, Grid2 } from '@mui/material'
import { CardTable, SORT } from 'components/datatable/Table'
import TabList from 'components/tab/TabList'
import { isNil, round } from 'lodash'
import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import useTitles from 'utils/customHook/useTitles'
import { getFullDate } from 'utils/DateUtil'
import PropTypes from 'prop-types'
import { BasicDialogTitle } from 'components/modal/DialogComponents'
import useBoolean from 'utils/customHook/useBoolean'
import Input from 'components/form/Input'

const TABS = {
    BACK: 'BACK',
    DB: 'DB',
    FRONT: 'FRONT',
}

const SERVICE_HEADERS = [
    { key: 'service', value: 'Service' },
    { key: 'apiName', value: 'Nom API' },
    { key: 'gitlabName', value: 'Nom Gitlab' },
    { key: 'port', value: 'Port' },
    { key: 'version', value: 'Version' },
    { key: 'commit', value: 'Commit' },
    { key: 'branch', value: 'Branche' },
    { key: 'deploy', value: 'Deploy' },
    { key: 'launch', value: 'Launch' },
    { key: 'inUseMemory', value: 'RAM utilisée' },
    { key: 'totalMemory', value: 'Allouée' },
    { key: 'maxMemory', value: 'Max' },
    { key: 'nbProcess', value: 'NB Process' },
]

const BackendTab = ({
    instance = {},
}) => {
    const {
        services = [],
    } = instance

    const formattedServices = services.map(r => ({
        service: { value: r.service.name, color: r.isRunning ? '#85ffab' : '#ff8e8e' },
        apiName: r.service.apiName,
        gitlabName: r.service.gitlabName,
        port: r.port,
        version: r.number,
        commit: {
            value: `${r.commitDate || ''} ${r.lastCommit ? `[${r.lastCommit.substring(0, 10)}]` : '[???]'}`,
            onClick: r.lastCommit ? () => window.open(`https://gitlab2.aquasys.fr/sieau/${r.service.gitlabName}/-/commit/${r.lastCommit}`, '_blank') : undefined,
            color: !r.lastCommit || !r.commitDate ? '#fd7d26' : undefined,
            fontColor: '#35609f',
        },
        branch: { value: r.branch, color: !r.branch ? '#fd7d26' : undefined },
        deploy: { value: getFullDate(r.deployDate), sortValue: r.deployDate, color: !r.deployDate ? '#fd7d26' : undefined },
        launch: { value: getFullDate(r.launchDate), sortValue: r.launchDate, color: !r.launchDate ? '#fd7d26' : undefined },
        inUseMemory: {
            value: r.inUseMemory ? `${round(r.inUseMemory / (1024*1024))} (${round(r.inUseMemory * 100 / r.maxMemory)}%)` : undefined,
            sortValue: r.inUseMemory ? round(r.inUseMemory * 100 / r.maxMemory) : undefined,
            color: r.inUseMemory && round(r.inUseMemory * 100 / r.maxMemory) >= 90 ? '#ff8e8e' : undefined,
        },
        totalMemory: { value: r.totalMemory ? round(r.totalMemory / (1024*1024)) : undefined },
        maxMemory: { value: r.maxMemory ? round(r.maxMemory / (1024*1024)) : undefined },
        nbProcess: r.nbProcess,
        url: r.instance.url,
    }))

    return (
        <Grid2 container spacing={1}>
            <Grid2 size={12}>
                <CardTable
                    title='Services'

                    rows={formattedServices}
                    headers={SERVICE_HEADERS}
                    defaultSort={ { column: 'service', direction: SORT.ASC } }
                />
            </Grid2>
        </Grid2>
    )
}

BackendTab.propTypes = {
    instance: PropTypes.shape({}),
}

const ScriptPlayDialog = ({
    isOpen = false,
    onClose = () => {},
    id,
    script = '',
}) => (
    <Dialog
        open={isOpen}
        maxWidth={'lg'}
    >
        <BasicDialogTitle onClose={onClose}>
            Script: {id}
        </BasicDialogTitle>
        <DialogContent>
            <Input
                value={script}
                multiline
            />
        </DialogContent>
    </Dialog>
)

ScriptPlayDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    script: PropTypes.string,
}

const PLAY_HEADERS = [
    { key: 'id', value: 'Identifiant' },
    { key: 'date', value: 'Date' },
    { key: 'hash', value: 'Hash' },
    { key: 'state', value: 'Statut' },
]

const DatabaseTab = ({
    instance = {},
}) => {
    const {
        playEvolutions = [],
    } = instance

    const formattedPlayEvolutions = playEvolutions.map(playEvo => ({
        id: playEvo.id,
        date: { value: getFullDate(playEvo.date), sortValue: playEvo.date },
        hash: playEvo.hash,
        state: playEvo.state,
    }))

    const {
        value: isOpen,
        setFalse: onClose,
        setTrue: onOpen,
    } = useBoolean(false)
    const [selectedPlayEvo, setSelectedPlayEvo] = useState()

    const selectedScript = playEvolutions.find(playEvo => playEvo.id === selectedPlayEvo)?.apply_script

    return (
        <Grid2 container spacing={1}>
            <Grid2 size={12}>
                <CardTable
                    title='Play évolution'

                    rows={formattedPlayEvolutions}
                    headers={PLAY_HEADERS}
                    defaultSort={{ column: 'date', direction: SORT.DESC }}
                    onClickRow={({ id }) => {
                        setSelectedPlayEvo(id)
                        onOpen()
                    }}
                />
                <ScriptPlayDialog
                    isOpen={isOpen}
                    onClose={onClose}
                    id={selectedPlayEvo}
                    script={selectedScript}
                />
            </Grid2>
        </Grid2>
    )
}

DatabaseTab.propTypes = {
    instance: PropTypes.shape({}),
}

const FRONT_HEADERS = [
    { key: 'application', value: 'Application' },
    { key: 'url', value: 'URL' },
    { key: 'version', value: 'Version' },
]

const FrontendTab = ({
    instance = {},
}) => {
    const {
        frontendVersions = [],
    } = instance

    const formattedFrontendVersion = frontendVersions.map(fv => ({
        application: fv.application,
        url: {
            value: fv.url,
            onClick: () => window.open(fv.url, '_blank'),
            fontColor: '#35609f',
        },
        version: fv.version,
    }))

    return (
        <Grid2 container spacing={1}>
            <Grid2 size={12}>
                <CardTable
                    title='Version Front-end'

                    rows={formattedFrontendVersion}
                    headers={FRONT_HEADERS}
                />
            </Grid2>
        </Grid2>
    )
}

FrontendTab.propTypes = {
    instance: PropTypes.shape({}),
}

const InstanceDasboardApp = ({

}) => {
    const { id } = useParams()
    const { instance } = useSelector(store => ({ instance: store.InstanceReducer.instance }), shallowEqual)

    useTitles(() => [{
        label: 'Instance',
        href: '/instance',
    }, {
        label: isNil(instance) ? 'Chargement...' : (instance.name ?? 'Inconnue'),
        href: `/instance/${id}`,
    }, {
        label: 'Dashboard',
        href: `/instance/${id}/dashboard`,
    }], [instance, id])

    return !isNil(instance) && (
        <Grid2 container spacing={1}>
            <Grid2 size={12}>
                <TabList
                    tabs={[
                        {
                            constant: TABS.BACK,
                            label: 'Back-end',
                            icon: 'lan',
                        },
                        {
                            constant: TABS.DB,
                            label: 'Base de donnée',
                            icon: 'storage',
                        },
                        {
                            constant: TABS.FRONT,
                            label: 'Front-end',
                            icon: 'desktop_access_disabled',
                        },
                    ]}
                    headerComponent={(
                        <Card sx={{ margin: '0 5 5 0' }}>
                            <CardContent sx={{ height: '36px' }}>
                                <Grid2 container columnSpacing={3} sx={{ height: '100%', margin: '0 10 5 10' }} alignItems='center'>
                                    <Grid2 container size='auto'>
                                        Back-end: {instance.statusBackend}
                                    </Grid2>
                                    <Grid2 container size='auto'>
                                        Base de donnée: {instance.statusDB}
                                    </Grid2>
                                    <Grid2 container size='auto'>
                                        Front-end: {instance.statusFrontend}
                                    </Grid2>
                                </Grid2>
                            </CardContent>
                        </Card>
                    )}
                >
                    {t => {
                        return (
                            <>
                                {t === TABS.BACK && (<BackendTab instance={instance} />)}
                                {t === TABS.DB && (<DatabaseTab instance={instance} />)}
                                {t === TABS.FRONT && (<FrontendTab instance={instance} />)}
                            </>
                        )
                    }}
                </TabList>
            </Grid2>
        </Grid2>
    )
}

export default InstanceDasboardApp