import { Button, Grid2 } from '@mui/material'
import DateTimeRangePicker from 'components/form/DateTimeRangePicker'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

const TimeErrorFilterFields = ({
    defaultFilter = {},
    onValidate = () => { },
}) => {
    const {
        instancesProps,
        servicesProps,
    } = useSelector(store => ({
        instancesProps: store.InstanceReducer.instances,
        servicesProps: store.ServiceReducer.services,
    }), shallowEqual)

    const [routingKey, setRoutingKey] = useState(defaultFilter.routingKey) // Option[String] = None
    const [url, setUrl] = useState(defaultFilter.url) // Option[String] = None
    const [startOfStart, setStartOfStart] = useState(defaultFilter.startOfStart) // Option[DateTime] = None
    const [endOfStart, setEndOfStart] = useState(defaultFilter.endOfStart) // Option[DateTime] = None
    const [startOfEnd, setStartOfEnd] = useState(defaultFilter.startOfEnd) // Option[DateTime] = None
    const [endOfEnd, setEndOfEnd] = useState(defaultFilter.endOfEnd) // Option[DateTime] = None
    const [level, setLevel] = useState(defaultFilter.level) // Option[Int] = None
    const [instances, setInstances] = useState(defaultFilter.instances ?? []) // Option[Seq[Int]] = None
    const [services, setServices] = useState(defaultFilter.services ?? []) // Option[Seq[Int]] = None
    const [appModule, setAppModule] = useState(defaultFilter.module) // Option[String] = None
    const [user, setUser] = useState(defaultFilter.user) // Option[String] = None

    const reset = () => {
        setRoutingKey()
        setUrl()
        setStartOfStart()
        setEndOfStart()
        setStartOfEnd()
        setEndOfEnd()
        setLevel()
        setInstances([])
        setServices([])
        setAppModule()
        setUser()
    }

    const validate = () => {
        const newFilter = {
            routingKey,
            url,
            startOfStart,
            endOfStart,
            startOfEnd,
            endOfEnd,
            level,
            instances,
            services,
            module: appModule,
            user,
        }
        onValidate(newFilter)
    }

    const formattedServices = useMemo(() => {
        return servicesProps.map(s => ({
            label: s.name,
            value: s.id,
        }))
    }, [servicesProps])
    const formattedInstances = useMemo(() => {
        return instancesProps.map(s => ({
            label: s.name,
            value: s.id,
        }))
    }, [instancesProps])

    const levels = useMemo(() => {
        return [
            { value: 1, label: '1' },
            { value: 2, label: '2' },
            { value: 3, label: '3' },
            { value: 4, label: '4' },
        ]
    }, [])

    return (
        <Grid2 container columnSpacing={2} alignItems='center' sx={{ marginBottom: '5px' }}>
            <Grid2 size={2}>
                <Input
                    label='Routing key'
                    value={routingKey}
                    onChange={setRoutingKey}
                />
            </Grid2>
            <Grid2 size={2}>
                <Input
                    label='URL'
                    value={url}
                    onChange={setUrl}
                />
            </Grid2>
            <Grid2 size={4}>
                <DateTimeRangePicker
                    label='Début de la requête'
                    value={[startOfStart, endOfStart]}
                    onChange={([start, end]) => {
                        setStartOfStart(start)
                        setEndOfStart(end)
                    }}
                />
            </Grid2>
            <Grid2 size={4}>
                <DateTimeRangePicker
                    label='Fin de la requête'
                    value={[startOfEnd, endOfEnd]}
                    onChange={([start, end]) => {
                        setStartOfEnd(start)
                        setEndOfEnd(end)
                    }}
                />
            </Grid2>
            <Grid2 size={2}>
                <Select
                    label='level'
                    items={levels}
                    value={level}
                    onChange={setLevel}
                    undefinedValue
                />
            </Grid2>
            <Grid2 size={2}>
                <Select
                    label='Instance'
                    items={formattedInstances}
                    value={instances}
                    onChange={setInstances}
                    undefinedValue
                    multiple
                />
            </Grid2>
            <Grid2 size={2}>
                <Select
                    label='Service'
                    items={formattedServices}
                    value={services}
                    onChange={setServices}
                    undefinedValue
                    multiple
                />
            </Grid2>
            <Grid2 size={2}>
                <Input
                    label='module'
                    value={appModule}
                    onChange={setAppModule}
                />
            </Grid2>
            <Grid2 size={2}>
                <Input
                    label='user'
                    value={user}
                    onChange={setUser}
                />
            </Grid2>
            <Grid2 size={2} />
            <Grid2 size={8} />
            <Grid2 size={2}>
                <Button
                    variant='contained'
                    fullWidth
                    onClick={reset}
                >
                    Annuler
                </Button>
            </Grid2>
            <Grid2 size={2}>
                <Button
                    variant='contained'
                    fullWidth
                    onClick={validate}
                >
                    Filtrer
                </Button>
            </Grid2>
        </Grid2>
    )
}

TimeErrorFilterFields.propTypes = {
    defaultFilter: PropTypes.shape({
        routingKey: PropTypes.string,
        url: PropTypes.string,
        startOfStart: PropTypes.number, // timestamp
        endOfStart: PropTypes.number, // timestamp
        startOfEnd: PropTypes.number, // timestamp
        endOfEnd: PropTypes.number, // timestamp
        level: PropTypes.number,
        instances: PropTypes.arrayOf(PropTypes.number),
        services: PropTypes.arrayOf(PropTypes.number),
        module: PropTypes.string,
        user: PropTypes.string,
    }),
    onValidate: PropTypes.func,
}

export default TimeErrorFilterFields