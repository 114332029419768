import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { MenuItem, TextField } from '@mui/material'
import { orderBy } from 'lodash'

const Select = ({
    label = '',
    value = '',
    items = [],
    onChange = () => { },
    disabled = false,
    sortItems = true,
    sortKey = 'label',
    undefinedValue = false,
    multiple = false,
}) => {
    const defaultValue = value ?? (multiple ? [] : '')

    const sortedItems = useMemo(() => {
        if (!sortItems) {
            return items
        }
        return orderBy(items, sortKey)
    }, [items, sortItems, sortKey])

    const formattedItems = useMemo(() => {
        const formatted = sortedItems.map(item => ({
            value: item.id ?? item.code ?? item.value,
            label: item.name || item.label || item.title || ' ',
        }))
        if (!undefinedValue) {
            return formatted
        }
        return [
            { value: undefined, label: '' },
            ...formatted,
        ]
    }, [sortedItems, undefinedValue])

    return (
        <TextField
            label={label}
            value={defaultValue}
            onChange={e => {
                if (multiple && e.target.value.includes(undefined)) {
                    onChange([])
                    return
                }
                onChange(e.target.value)
            }}
            select
            disabled={disabled}
            SelectProps={{
                multiple,
            }}
        >
            {
                formattedItems.map((obj, i) => (<MenuItem key={i} value={obj.value}>{obj.label}</MenuItem>))
            }
        </TextField>
    )
}

Select.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]),
    items: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        // + sortKey
        // + name, title, id, code
    })),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    sortItems: PropTypes.bool,
    sortKey: PropTypes.string,
    undefinedValue: PropTypes.bool,
    multiple: PropTypes.bool,
}

export default Select