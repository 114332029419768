import { CardTable, SORT } from 'components/datatable/Table'
import React, { useEffect, useMemo, useState } from 'react'
import TimeErrorFilterFields from './TimeErrorFilterFields'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ServiceAction from 'service/actions/ServiceAction'
import InstanceAction from 'instance/actions/InstanceAction'
import useListIndexed from 'utils/customHook/useListIndexed'
import { getDuration, getLevelColor } from 'timeError/constants/TimeErrorConstant'
import { getFullDate, shortenHumanize } from 'utils/DateUtil'
import { Card, CardContent } from '@mui/material'
import TimeErrorAction from 'timeError/actions/TimeErrorAction'
import moment from 'moment'
import useBoolean from 'utils/customHook/useBoolean'
import PopinGraphTimeErrors from './PopinGraphTimeErrors'
import useTitles from 'utils/customHook/useTitles'

const TABLE_HEADERS = [
    { key: 'routingKey', value: 'Routing key' },
    { key: 'url', value: 'URL' },
    { key: 'instance', value: 'Instance' },
    { key: 'service', value: 'Service' },
    { key: 'requestStart', value: 'Début de la requête' },
    { key: 'processStart', value: 'Début du traitement' },
    { key: 'processEnd', value: 'Fin du traitement' },
    { key: 'duration', value: 'Duré' },
    { key: 'user', value: 'utilisateur' },
    { key: 'module', value: 'module' },
]

const TimeErrorsApp = () => {
    const dispatch = useDispatch()
    const {
        timeErrors,
    } = useSelector(store => ({
        timeErrors: store.TimeErrorReducer.timeErrors,
    }), shallowEqual)

    useTitles(() => [{
        label: 'Time error',
        href: '/timeError',
    }], [])

    const [filter, setFilter] = useState({ startOfStart: moment().subtract(1, 'month').valueOf() })
    const [selected, setSelected] = useState({})
    const {
        value: isOpen,
        setTrue: onOpen,
        setFalse: onClose,
    } = useBoolean()

    useEffect(() => {
        dispatch(ServiceAction.fetchServices())
        dispatch(InstanceAction.fetchInstances())
    }, [dispatch])

    const {
        services,
        instances,
    } = useSelector(store => ({
        services: store.ServiceReducer.services,
        instances: store.InstanceReducer.instances,
    }), shallowEqual)

    const indexedInstances = useListIndexed(instances)
    const indexedServices = useListIndexed(services)

    useEffect(() => {
        dispatch(TimeErrorAction.fetchTimeErrors(filter))
    }, [dispatch, filter])

    const formattedTimeErrors = useMemo(() => {
        return timeErrors.map(te => {
            const duration = getDuration(te)
            return {
                routingKey: { value: te.routingKey, color: getLevelColor(te.level) },
                url: { value: te.url },
                instance: { value: indexedInstances[te.instance]?.name },
                service: { value: indexedServices[te.service]?.name },
                requestStart: { value: getFullDate(te.requestStart), sortValue: te.requestStart },
                processStart: { value: getFullDate(te.processStart), sortValue: te.processStart },
                processEnd: { value: getFullDate(te.processEnd), sortValue: te.processEnd },
                duration: { value: shortenHumanize(duration), sortValue: duration },
                user: { value: te.user },
                module: { value: te.module },
                info: {
                    routingKey: te.routingKey,
                    instance: te.instance,
                },
                /*
                    this.referer = obj.referer // Option[String] = None,
                    this.userAgent = obj.userAgent // Option[String] = None,
                    this.body = obj.body // Option[String] = None,
                    this.size = obj.size // Option[String] = None
                */
            }
        })
    }, [indexedInstances, indexedServices, timeErrors])

    return (
        <div style={{ margin: '10', paddingBottom: '50px' }}>
            <Card sx={{ marginBottom: '5px' }}>
                <CardContent>
                    <TimeErrorFilterFields
                        defaultFilter={filter}
                        onValidate={setFilter}
                    />
                </CardContent>
            </Card>
            <CardTable
                title={'Time errors'}
                color='#161832'

                rows={formattedTimeErrors}
                headers={TABLE_HEADERS}
                onClickRow={({ info }) => {
                    setSelected(info)
                    onOpen()
                }}

                actions={[{
                    icon: 'replay',
                    onClick: () => dispatch(TimeErrorAction.fetchTimeErrors(filter)),
                    tooltip: 'Recharger',
                }]}

                defaultSort={{ column: 'requestStart', direction: SORT.DESC }}
            />
            <PopinGraphTimeErrors
                open={isOpen}
                onClose={onClose}
                filters={selected}
            />
        </div>
    )
}

export default TimeErrorsApp