import { keyframes } from '@emotion/react'
const pulse = keyframes`
        0% {
            -moz-box-shadow: 0 0 0 0 rgba(22, 24, 50, 0.8);
            box-shadow: 0 0 0 0 rgba(22, 24, 50, 0.8);
        }
        70% {
            -moz-box-shadow: 0 0 0 20px rgba(22, 24, 50, 0);
            box-shadow: 0 0 0 20px rgba(22, 24, 50, 0);
        }
        100% {
            -moz-box-shadow: 0 0 0 0 rgba(22, 24, 50, 0);
            box-shadow: 0 0 0 0 rgba(22, 24, 50, 0);
        }
    `

const whitePulse = keyframes`
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.8);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.8);
    }
    70% {
        -moz-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
`

const pulseGrow = keyframes `
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
`

const rotate = keyframes`
        100% {
            transform: rotate(360deg)
        }
`

export { pulse, rotate, whitePulse, pulseGrow }