import React, { useEffect } from 'react'
import BoundaryError from 'components/BoundaryError'
import { Outlet, useParams } from 'react-router-dom'
import NavBar from 'NavBar'
import InstanceAction from 'instance/actions/InstanceAction'
import { useDispatch } from 'react-redux'
import { isNil } from 'lodash'
import { InstanceActionConstant } from 'instance/reducers/InstanceReducer'

const InstanceApp = ({

}) => {
    const { id } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        if (isNil(id)) return
        dispatch(InstanceAction.fetchInstance(id))

        return () => {
            dispatch(InstanceActionConstant.resetInstance())
        }
    }, [dispatch, id])

    const links = [
        { icon: 'dashboard', tooltip: 'Dashboard', url: `/instance/${id}/dashboard` },
        { icon: 'compare_arrows', tooltip: 'Description', url: `/instance/${id}/description` },
    ]
    return (
        <>
            <NavBar links={links} />
            <BoundaryError>
                <div style={{ padding: '10px', marginLeft: '65px' }}>
                    <Outlet />
                </div>
            </BoundaryError>
        </>
    )
}

export default InstanceApp