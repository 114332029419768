import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Masonry } from '@mui/lab'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Card, CardContent, Grid2 } from '@mui/material'
import AuthAction from '../actions/AuthAction'
import { useNavigate } from 'react-router-dom'
import useTitles from '../../utils/customHook/useTitles'

const UserCard = ({ user }) => {
    const navigate = useNavigate()

    return (
        <Card onClick={ () => navigate(`/user/${user.login}`) } style={{ cursor: 'pointer' }}>
            <CardContent>
                <Grid2
                    container
                    spacing={1}
                    direction='column'
                    justify='center'
                    alignItems='center'
                    style={{ minHeight: '15vh' }}
                >
                    <Grid2 size='auto' style={{ height: 90, width: 90 }}>
                        <img
                            src={user.picture}
                            alt=''
                            width='100%'
                            style={ { borderRadius: '15%' } }
                        />
                    </Grid2>
                    <Grid2 size='auto' >
                        <b style={{ fontSize: 15 }}>{ user.login }</b>
                    </Grid2>
                    <Grid2 size='auto' >
                        <b style={{ fontSize: 12 }}>{ user.email }</b>
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

UserCard.propTypes = {
    user: PropTypes.shape({
        picture: PropTypes.string,
        login: PropTypes.string,
        email: PropTypes.string,
    }),
}

const UsersApp = ({

}) => {
    const dispatch = useDispatch()

    const {
        users,
    } = useSelector(store => ({
        users: store.AdminReducer.users,
    }), shallowEqual)

    useEffect(() => {
        dispatch(AuthAction.fetchUsers())
    }, [dispatch])

    useTitles(() => [{
        label: 'Utilisateurs',
        href: '/user',
    }], [])

    return (
        <div style={{ margin: '10' }}>
            <Masonry columns={8} spacing={1}>
                {users.map(user => (<UserCard key={user.login} user={user} />))}
            </Masonry>
        </div>
    )
}

export default UsersApp