import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import FieldLabel from './FieldLabel'

const RadioButton = ({
    label,
    value,
    disabled = false,
}) => (
    <FormControlLabel
        label={(
            <Typography
                sx={{ color: disabled ? '#9e9e9e' : '#161832', fontSize: '0.8rem' }}
            >
                {label}
            </Typography>
        )}
        value={value}
        control={<Radio />}
        disabled={disabled}
        sx={{ marginLeft: '0' }}
    />
)

RadioButton.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
}

const RadioButtons = ({
    label,
    value,
    items = [],
    onChange = () => {},

    required = false,
    disabled = false,
}) => {
    return (
        <FormControl>
            <FieldLabel label={label} required={required} />
            <RadioGroup
                row
                value={value}
                onChange={(_, newV) => onChange(newV)}
            >
                {items.map(item => (<RadioButton key={item.value} value={item.value} label={item.label} disabled={disabled} />))}
            </RadioGroup>
        </FormControl>
    )
}

RadioButtons.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    items: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
    })),
    onChange: PropTypes.func,

    required: PropTypes.bool,
    disabled: PropTypes.bool,
}

export default RadioButtons