import React, { useEffect, useRef } from 'react'
import { Icon, IconButton, InputAdornment, TextField } from '@mui/material'
import { useState } from 'react'
import useDebounce from 'utils/customHook/useDebounce'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'

const Input = ({
    value = '',
    onEnterKeyPress = () => { },
    onChange = () => { },
    label,
    disabled = false,
    type = 'text',
    debounceDelay = 500,

    // type = text
    multiline = false,

    // type = int || float
    min,
    max,
}) => {
    const [stateValue, setStateValue] = useState(`${value ?? ''}`)
    const shouldDebounce = useRef(true)

    useEffect(() => {
        setStateValue(`${value ?? ''}`)
        shouldDebounce.current = false
    }, [value])

    const getParseValue = v => {
        switch (type) {
            case 'int': return parseInt(v)
            case 'float': return parseFloat(v)
            default: return v
        }
    }

    useDebounce(() => {
        if (!shouldDebounce.current) {
            return
        }
        if ((type === 'int' || type === 'float') && stateValue === '-') {
            return
        }
        if (type === 'float' && stateValue.endsWith('.')) {
            return
        }
        onChange(stateValue === '' ? undefined : getParseValue(stateValue))
    }, debounceDelay, [stateValue])

    const formatMinMax = v => {
        if (v === '-') {
            return v
        }
        if (v.endsWith('.')) {
            return v
        }
        const parseV = type === 'int' ? parseInt(v) : parseFloat(v)
        const vMin = isNil(min) ? parseV : Math.max(parseV, min)
        const vMax = isNil(max) ? vMin : Math.min(vMin, max)
        return `${vMax}`
    }

    const handleChange = e => {
        shouldDebounce.current = true
        if (e.target.value === '') {
            setStateValue('')
            return
        }
        if (type === 'int') {
            const regex = /^-?\d*$/
            if (regex.test(e.target.value)) {
                setStateValue(formatMinMax(e.target.value))
            }
            return
        }
        if (type === 'float') {
            const regex = /^-?\d*(\.\d*)?$/
            if (regex.test(e.target.value)) {
                setStateValue(formatMinMax(e.target.value))
            }
            return
        }
        setStateValue(e.target.value)
    }

    return (
        <TextField
            value={stateValue}
            onChange={handleChange}
            // placeholder={placeholder}
            onKeyUp={(event) => {
                if (event.key == 'Enter') {
                    onEnterKeyPress()
                }
            }}
            label={label}
            disabled={disabled}
            inputProps={{
                style: {
                    border: 'none',
                    boxShadow: 'none',
                },
            }}
            multiline={multiline}
        />
    )
}

Input.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onEnterKeyPress: PropTypes.func,
    onChange: PropTypes.func,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    debounceDelay: PropTypes.number,
    type: PropTypes.oneOf(['text', 'int', 'float']),
    multiline: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
}

const SearchAdornment = ({
    search = () => { },
}) => (
    <InputAdornment position='end'>
        <IconButton
            aria-label='search'
            onClick={search}
            edge='end'
        >
            <Icon>search</Icon>
        </IconButton>
    </InputAdornment>
)

SearchAdornment.propTypes = {
    search: PropTypes.func,
}

export default Input
export {
    SearchAdornment,
}