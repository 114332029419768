import React from 'react'
import PropTypes from 'prop-types'
import { Grid2, Icon, IconButton, Tooltip } from '@mui/material'
import { useDispatch } from 'react-redux'
import { push } from '@lagunovsky/redux-react-router'

const NavBar = ({ links = [] }) => {
    const dispatch = useDispatch()
    return (
        <div style={{ position: 'fixed', width: '65px', height: '100%', paddingTop: '10px', backgroundColor: '#fafafa', boxShadow: '0 16px 24px 2px rgba(0,0,0,0.14),0 6px 30px 5px rgba(0,0,0,0.12),0 8px 10px -5px rgba(0,0,0,0.3)' }}>
            <Grid2 container spacing={1} direction='column' justifyContent='flex-start' alignItems='center'>
                {
                    links.map(link => (
                        <Grid2 key={link.url}>
                            <Tooltip title={link.tooltip}>
                                <IconButton sx={{ fontSize: '24px', padding: '6px' }} onClick={() => dispatch(push(link.url))}>
                                    <Icon fontSize='inherit' sx={{ color: '#777' }}>{link.icon}</Icon>
                                </IconButton>
                            </Tooltip>
                        </Grid2>
                    ))
                }
            </Grid2>
        </div>
    )
}

NavBar.propTypes = {
    links: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        href: PropTypes.string.isRequired,
        name: PropTypes.string,
        className: PropTypes.string,
        icons: PropTypes.string,
        external: PropTypes.bool,
        tooltip: PropTypes.bool,
    })).isRequired,
}

export default NavBar
