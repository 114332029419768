import React from 'react'
import PropTypes from 'prop-types'
import { Chip as MuiChip, Tooltip } from '@mui/material'
import { getTextColorByHexColor } from 'utils/ColorUtil'

const Chip = ({
    label = '',
    color = '',
    tooltip,
    onClick,
}) => {
    if (tooltip) {
        return (
            <Tooltip title={tooltip}>
                <MuiChip size='small' label={label} onClick={onClick} sx={{ backgroundColor: color, color: getTextColorByHexColor(color), fontSize: '16px' }} />
            </Tooltip>
        )
    }
    return (
        <MuiChip size='small' label={label} onClick={onClick} sx={{ backgroundColor: color, color: getTextColorByHexColor(color), fontSize: '16px' }} />
    )
}

Chip.propTypes = {
    label: PropTypes.string,
    color: PropTypes.string,
    tooltip: PropTypes.string,
    onClick: PropTypes.func,
}

export default Chip