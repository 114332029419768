import { Grid2 } from '@mui/material'
import stormError from 'assets/pictures/pictos/stormError.png'
import React from 'react'

const AquaKwak = () => (
    <div className='col s12'>
        <div className='row no-margin'>
            <div className='card'>
                <div className='row card-content'>
                    <Grid2 container justifyContent='center' alignItems='center' spacing={3}>
                        <Grid2 size={8}>
                            <img src={stormError} style={{
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                width: '30%',
                            }}
                            />
                        </Grid2>
                        <Grid2 size={8}>
                            <h1 style={{ textAlign: 'center' }}>Oops! Une erreur est survenue.</h1>
                        </Grid2>
                        <Grid2 size={8}>
                            <label style={{ textAlign: 'center', fontSize: '2rem' }}>Nous sommes désolés, il semblerait que l'application ait rencontré un problème inconnu. Si le problème persiste, veuillez contacter le support.</label>
                        </Grid2>
                    </Grid2>
                </div>
            </div>
        </div>
    </div>
)

export default AquaKwak